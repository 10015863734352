import { User } from '../hooks/useAuthOrAnon';
import { Streak } from '../hooks/useStreak';
import Auth from './auth';
import Streaks from './streaks';

import getCurrentStreak from '../utils/getCurrenStreak';
import getLast7Days from '../utils/getLast7Days';

export interface HandleAuthAndSetupUIProps {
  gameKey: string;
  gameTitle: string;
  level: string;
  svgSrc: string;
  riveSrc: string;
  howToPlayURL: string;
}

async function renderProfileDropdown(
  user: User,
  gameTitle: string,
  streakLogs: string[],
  streaks: Streak[][],
  gameKey: string,
) {
  const currentStreak = getCurrentStreak(streaks[streaks.length - 1]);

  try {
    const response = await fetch('/template/navbar.html');
    const templateHTML = await response.text();

    const templateContainer = document.createElement('div');
    templateContainer.innerHTML = templateHTML;

    const streakElement = templateContainer.querySelector('#current-streak');
    const usernameElement = templateContainer.querySelector('#username');
    const streakDaysContainer = templateContainer.querySelector('#days');
    const statsButton = templateContainer.querySelector(
      '#accountStatsBtn',
    ) as HTMLAnchorElement;

    streakElement!.textContent = `${currentStreak ? '🔥' : '🥶'} ${currentStreak}`;
    usernameElement!.textContent = `@${user.username}`;
    statsButton.href = `https://play.hey.gg/dashboard/${gameKey}?redirect_uri=`;
    statsButton.textContent = `${gameTitle} Stats`;

    const streakDaysHTML = getLast7Days()
      .map((day, i) => {
        const isStreakDay = streakLogs.includes(day);
        const isCurrentDay = i === 6;
        return `<div class="day ${
          isStreakDay ? (isCurrentDay ? 'streakCurrentDay' : 'streak') : ''
        }">
                    ${day.split('-')[2]}
                  </div>`;
      })
      .join('');
    streakDaysContainer!.innerHTML = streakDaysHTML;

    const headerRightContainer = document.querySelector(
      'body > div > div.appShell > header > div.right',
    );
    headerRightContainer?.appendChild(templateContainer);

    const dropdownContent = templateContainer.querySelector('.dropdown-content');
    const userProfileButton = templateContainer.querySelector(
      '.user-profile-dropdown',
    );
    userProfileButton?.addEventListener('click', () => {
      dropdownContent?.classList.toggle('open');
    });

    // Close dropdown on outside click
    document.addEventListener('mousedown', (event) => {
      if (!templateContainer.contains(event.target as Node | null)) {
        dropdownContent?.classList.remove('open');
      }
    });
  } catch (error) {
    console.error('Error loading profile dropdown:', error);
  }
}

function updateSidebar(user: User, currentPlayStreak: number) {
  const playStreakElement = document.getElementById('current-play-streak');
  const usernameElement = document.getElementById('username');

  if (playStreakElement) {
    playStreakElement.textContent = currentPlayStreak.toString();
  }

  if (usernameElement) {
    usernameElement.textContent = user.username;
  }
}

async function renderAccountMessage(
  gameTitle: string,
  svgSrc: string,
  gameKey: string,
  howToPlayURL: string,
) {
  try {
    const response = await fetch('/template/account-message.html');
    const templateHTML = await response.text();

    const templateContainer = document.createElement('div');
    templateContainer.innerHTML = templateHTML;

    templateContainer.querySelector('#account-logo')?.setAttribute('src', svgSrc);
    templateContainer.querySelector('#account-logo')?.setAttribute('alt', gameTitle);
    templateContainer.querySelector(
      '#game-title',
    )!.textContent = `NEW! ${gameTitle.toUpperCase()} ACCOUNTS`;
    templateContainer
      .querySelector('#free-account-link')
      ?.setAttribute('href', 'https://play.hey.gg/register?redirect_uri=');
    templateContainer
      .querySelector('#stats-link')
      ?.setAttribute(
        'href',
        `https://play.hey.gg/dashboard/${gameKey}?redirect_uri=`,
      );
    templateContainer
      .querySelector('#how-to-play-link')
      ?.setAttribute('href', howToPlayURL);

    const sidebar = document.getElementById('sidebar');
    const sidebarHeader = sidebar?.querySelector('.sidebar-header');
    const sidebarMenu = sidebar?.querySelector('.sidebar-menu');

    sidebar?.replaceChild(templateContainer, sidebarHeader!);
    sidebar?.replaceChild(templateContainer, sidebarMenu!);
  } catch (error) {
    console.error('Error loading account message:', error);
  }
}

function updateRedirectLinks() {
  document.querySelectorAll('a[href*="redirect_uri="]').forEach((link) => {
    const currentHref = link.getAttribute('href');
    if (currentHref?.endsWith('redirect_uri=')) {
      const newHref = currentHref + encodeURIComponent(window.location.href);
      link.setAttribute('href', newHref);
    }
  });
}

async function initializeAppShell() {
  const heyggBody = document.createElement('div');
  heyggBody.classList.add('heygg-body');

  const [appShellHTML, footerHTML] = await Promise.all([
    fetch('/template/app-shell.html').then((res) => res.text()),
    fetch('/template/footer.html').then((res) => res.text()),
  ]);

  const appShellContainer = document.createElement('div');
  appShellContainer.classList.add('appShell');
  appShellContainer.innerHTML = appShellHTML;

  const mainContent = document
    .getElementsByClassName('main-content')[0]
    .cloneNode(true) as HTMLElement;

  document.body.replaceChildren(heyggBody);
  heyggBody.appendChild(appShellContainer);
  heyggBody.appendChild(mainContent);

  heyggBody.innerHTML += footerHTML;
}

function addScriptToHead(src: string, async = true, defer = true) {
  const script = document.createElement('script');
  script.src = src;
  script.async = async;
  script.defer = defer;
  document.head.appendChild(script);
}

async function handleAuthAndSetupUI({
  gameKey,
  gameTitle,
  level,
  svgSrc,
  riveSrc,
  howToPlayURL,
}: HandleAuthAndSetupUIProps) {
  addScriptToHead('https://unpkg.com/@rive-app/canvas', false, false);

  await initializeAppShell();

  const sidebar = document.getElementById('sidebar');
  const header = document.querySelector('body > div > div.appShell > header');

  document.addEventListener('mousedown', (event) => {
    if (
      sidebar &&
      header &&
      !sidebar.contains(event.target as any) &&
      !header?.contains(event.target as any)
    ) {
      sidebar.classList.remove('sidebar-active');
    }
  });

  updateRedirectLinks();

  const today = new Date();
  const sevenDaysAgo = new Date();
  const since = new Date(sevenDaysAgo.setDate(today.getDate() - 7))
    .toISOString()
    .split('T')[0];

  try {
    const { user } = await Auth.getAuthStatus();

    if (user) {
      console.log('Logged in! : ', user);

      const [streakLogs = [], streaks = [[]]] = await Promise.all([
        Streaks.getLogs(gameKey, level, since),
        Streaks.getStreaks(gameKey, level),
      ]);

      const currentStreak = getCurrentStreak(streaks[streaks.length - 1]);

      const navbar = document.querySelector(
        'body > div.heygg-body > div.appShell > header > div.right > ul:nth-child(1)',
      ) as HTMLElement;

      navbar.remove();

      renderProfileDropdown(user, gameTitle, streakLogs, streaks, gameKey);
      updateSidebar(user, currentStreak);
    } else {
      console.log('Not logged in!');

      renderAccountMessage(gameTitle, svgSrc, gameKey, howToPlayURL);
    }
  } catch (error) {
    console.error('Error loading profile dropdown:', error);
  }

  updateRedirectLinks();

  const rive = (window as any)?.rive;
  if (rive) {
    new rive.Rive({
      src: riveSrc,
      canvas: document.getElementById('canvas'),
      autoplay: true,
      stateMachines: 'State Machine 1',
    });
  }
}

const toggleSidebar = () => {
  const sidebarElement = document.getElementById('sidebar');
  if (sidebarElement) {
    sidebarElement.classList.toggle('sidebar-active');
  }
};

export { handleAuthAndSetupUI, toggleSidebar };
